<smw-auth-template>
  <div>
    <smw-icon class="logo" [icon]="logoIcon" />

    <h1 class="title-md mb-3">{{ 'auth_password-reset_title' | translate }}</h1>

    @if (passwordReset) {
      <p class="body-lg mb-10">{{ 'auth_password-reset_ok' | translate }}</p>
      <a smw-button kind="filled" [routerLink]="['auth' | route]">{{ 'back-home' | translate }}</a>
    } @else {
      @if (form) {
        <form [formGroup]="form">
          <div class="w-full">
            <smw-password
              formControlName="password"
              [placeholder]="'input-password-placeholder' | translate"
            />
            @if (form.get('password')?.hasError('pattern')) {
              <p class="caption-sm text-secondary-red-500">
                {{ 'error-input-pattern-password' | translate }}
              </p>
            }

            @if (resetPasswordErrorMessage()) {
              <p class="caption-sm text-secondary-red-500">
                {{ resetPasswordErrorMessage() }}
              </p>
            }
          </div>
        </form>
      }
    }
  </div>

  @if (!passwordReset) {
    <button smw-button kind="filled" class="confirm" [disabled]="isDisabled" (click)="submit()">
      {{ 'confirm' | translate }}
    </button>
  }
</smw-auth-template>
