import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

type RainW = {
  color: string;
  rotate: number;
  left: number;
  speed: number;
};

@Component({
  selector: 'smw-rain-w',
  imports: [NgStyle, IconComponent],
  templateUrl: './rain-w.component.html',
  styleUrls: ['./rain-w.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainWComponent implements OnInit {
  constructor(private changeDetector: ChangeDetectorRef) {}

  rainW!: RainW[];
  icon = Icon.wLogo;

  ngOnInit(): void {
    const length = 20;
    this.rainW = [];

    for (let i = 0; i < length; i++) {
      this.rainW[i] = {
        color: ['#FEE85D', '#5EC3AE', '#465BD8', '#EA5F4C'][Math.floor(Math.random() * 4)],
        rotate: Math.floor(Math.random() * 361),
        left: Math.random() * (100 - 1) + 1,
        speed: Math.random() * 0.75 + 1.5,
      };
    }

    this.changeDetector.markForCheck();
  }
}
