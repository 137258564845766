export const CompanyPlugins = {
  workoscope: 'workoscope',
  interview: 'interview',
  b2c: 'b2c',
  synthese: 'synthese',
  mobility: 'mobility',
  ai: 'ai',
  training: 'training',
  hrdashboard: 'hrdashboard',
  bohrusers: 'bohrusers',
  bohrcampaigns: 'bohrcampaigns',
  bohrevents: 'bohrevents',
} as const;

export type CompanyPlugins = (typeof CompanyPlugins)[keyof typeof CompanyPlugins];
