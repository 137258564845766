<a
  *breakpoints="['md']"
  smw-button
  kind="text"
  [icon]="icon"
  [title]="'nav-header_back_a11y' | translate"
  (click)="back()"
>
  {{ 'back' | translate }}
</a>

<a
  *breakpoints="['mobile', 'sm']"
  smw-button
  kind="icon"
  [icon]="icon"
  [title]="'nav-header_back_a11y' | translate"
  (click)="back()"
>
  {{ 'back' | translate }}
</a>
