import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Inject,
  input,
} from '@angular/core';

import { NotificationType } from './notification';
import { NotificationService } from './notification.service';
import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  imports: [IconComponent],
  selector: 'smw-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  constructor(
    @Inject(forwardRef(() => NotificationService))
    private service: NotificationService,
  ) {}

  title = input<string | undefined>();
  message = input.required<string>();
  type = input<NotificationType>('info');

  closeIcon = Icon.close;

  close() {
    this.service.close();
  }

  @HostBinding('class') get notificationClass(): string {
    return this.type();
  }
}
