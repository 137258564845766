import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  Renderer2,
  signal,
  viewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Icon } from '../../icon';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'smw-password',
  imports: [IconComponent],
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PasswordComponent,
      multi: true,
    },
  ],
})
export class PasswordComponent implements ControlValueAccessor {
  constructor(
    @Attribute('id') public id: string,
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    if (this.id) {
      this.inputId = this.id;
      this.renderer.removeAttribute(this.element.nativeElement, 'id');
    }
  }

  label = input('');
  placeholder = input('');
  invalid = input(false);

  inputField = viewChild<ElementRef<HTMLInputElement>>('input');

  inputId?: string;
  value = signal('');

  isHidden = signal(true);
  icon = computed(() => (this.isHidden() ? Icon.eyeFilled : Icon.eyeInvisible));
  inputType = computed(() => (this.isHidden() ? 'password' : 'text'));

  onChange!: (value: string) => void;
  onTouched!: () => void;
  onValidationChange!: () => void;

  writeValue(value: string): void {
    this.value.set(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    const field = this.inputField();
    if (field) {
      this.renderer.setProperty(field.nativeElement, 'disabled', isDisabled);
    }
  }

  changePasswordVisibility(): void {
    this.isHidden.update((isHidden) => !isHidden);
  }
}
