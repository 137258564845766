@if (isEnabled() && show()) {
  <div class="wrapper">
    @if (templateText(); as template) {
      <div class="body-sm">
        <ng-container [ngTemplateOutlet]="template"></ng-container>
      </div>
    } @else {
      <div class="body-sm" [innerHTML]="text()"></div>
    }
  </div>
}
