import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  output,
  Renderer2,
  viewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../../../button/button.component';
import { Icon } from '../../../icon';

@Component({
  selector: 'smw-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonComponent],
})
export class CommentInputComponent {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  disabled = input<boolean>(false);
  placeholder = input<string>();

  confirm = output<string>();

  textareaRef = viewChild<unknown, ElementRef<HTMLTextAreaElement>>('textarea', {
    read: ElementRef<HTMLTextAreaElement>,
  });

  sendIcon = Icon.send;

  send(): void {
    if (this.disabled()) {
      return;
    }

    const comment = this.textareaRef()?.nativeElement.value;

    if (!comment) {
      return;
    }

    this.confirm.emit(comment);
    this.clear();
    this.autoresize();
  }

  onInput(): void {
    this.autoresize();
  }

  private clear(): void {
    const element = this.textareaRef()?.nativeElement;
    if (!element) {
      return;
    }
    this.renderer.setProperty(element, 'value', '');
  }

  private autoresize(): void {
    const wrapper = this.elementRef.nativeElement;
    const textarea = this.textareaRef()?.nativeElement;

    if (!textarea) {
      return;
    }

    this.renderer.setStyle(textarea, 'height', 'auto');

    const scrollHeight = textarea.scrollHeight;
    const currentStyle = getComputedStyle(textarea);
    const currentFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const minHeight = parseFloat(currentStyle.minHeight);
    const maxHeight = parseFloat(currentStyle.maxHeight);

    if (scrollHeight > maxHeight) {
      this.renderer.setStyle(textarea, 'height', `${maxHeight}px`);
      this.renderer.setStyle(textarea, 'overflowY', `auto`);
    } else {
      const height = Math.max(scrollHeight, minHeight);
      this.renderer.setStyle(textarea, 'height', `${height}px`);
      this.renderer.setStyle(textarea, 'overflowY', `hidden`);

      const borderRadius = height > currentFontSize * 3 ? 2 : 4;

      this.renderer.setStyle(wrapper, 'border-radius', `${borderRadius}rem`);
      this.renderer.setStyle(textarea, 'border-radius', `${borderRadius}rem`);
    }
  }
}
