@if (label(); as label) {
  <label class="caption-sm">{{ label }}</label>
}
<div
  #selectContainer
  class="box body-md text-black"
  [class.disabled]="isDisabled()"
  [class.invalid]="invalid()"
  (click)="isMenuDisplayed.set(!isMenuDisplayed())"
>
  <div class="selected">
    @if (!selectedOption() && placeholder(); as placeholder) {
      <span class="placeholder">{{ placeholder }}</span>
    }

    @if (selectedOption(); as selectedOption) {
      <ng-container
        [ngTemplateOutlet]="optionTemplate || defaultOptionTemplate"
        [ngTemplateOutletContext]="{ option: selectedOption }"
      >
      </ng-container>
    }
  </div>

  <div class="arrow">
    <smw-icon [icon]="icon" />
  </div>
</div>

<ul
  class="options"
  [style.display]="isMenuDisplayed() && !isDisabled() ? 'block' : 'none'"
  (clickOutside)="close()"
>
  @for (option of options(); track option.value) {
    <li class="item" [class.disabled]="option.disabled" (click)="select(option)">
      <ng-container
        [ngTemplateOutlet]="optionTemplate || defaultOptionTemplate"
        [ngTemplateOutletContext]="{ option }"
      >
      </ng-container>
    </li>
  }
</ul>

<ng-template #defaultOptionTemplate let-option="option">
  <span class="option">{{ option?.label ?? '' }}</span>
</ng-template>
