import { Component, input } from '@angular/core';

import { DurationPipe } from '@smw/utils-front';

import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-card-duration',
  imports: [IconComponent, DurationPipe],
  template: `
    <smw-icon [icon]="icon" />
    <p class="caption-sm mb-0">{{ duration() | duration }}</p>
  `,
  styleUrls: ['./card-duration.component.less'],
})
export class CardDurationComponent {
  duration = input.required<number>();
  icon = Icon.timer;
}
