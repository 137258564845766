import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import posthog from 'posthog-js';

import { intercom } from '@smw/analytics-front';
import { UserRole } from '@smw/common-models';
import { RoutePipe } from '@smw/navigation-front';
import { Space, UserStore } from '@smw/stores-front';
import { Icon, IconComponent, MenuItemDirective } from '@smw/ui-front';
import { showSentryFeedback } from '@smw/utils-front';

@Component({
  selector: 'smw-sidebar-menu',
  imports: [
    TranslateModule,
    RouterLink,
    RouterLinkActive,
    MenuItemDirective,
    IconComponent,
    RoutePipe,
  ],
  templateUrl: 'menu.component.html',
  styleUrls: ['./menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent {
  company = input.required<string>();
  roles = input.required<UserRole[]>();
  logout = output();
  changeSpace = output<Space | 'formateur'>();
  closeSidebar = output();

  isCollaborator = computed(() => this.roles().includes('collaborator'));
  isManager = computed(() => this.roles().includes('manager'));
  isHr = computed(() => this.roles().includes('hr'));
  isFormateur = computed(() => this.roles().includes('formateur'));

  accountIcon = Icon.user;
  helpIcon = Icon.help;
  logoutIcon = Icon.logout;
  collaboratorIcon = Icon.collaborator;
  managerIcon = Icon.manager;
  hrIcon = Icon.hr;
  coachIcon = Icon.trainingOutline;

  constructor(private userStore: UserStore) {}

  selectCollaboratorSpace(): void {
    this.changeSpace.emit('collaborator');
    this.closeSidebar.emit();
  }

  handleSupportClick() {
    if (this.userStore.isInternalSMWUser()) {
      showSentryFeedback();
    } else {
      this.showIntercomMenu();
    }
  }
  // Sentry feedback is just out of beta and a few things are still a bit missing documentation
  // https://docs.sentry.io/product/user-feedback/
  // This thread https://github.com/getsentry/sentry/discussions/60134 mention several info like for example how to send webhooks from alerts and include context data
  showSentryFeedback(): void {
    const lastReplayUrl = posthog?.get_session_replay_url();
    Sentry.setTag('latest_posthog_replay', lastReplayUrl);
    Sentry.setContext('lastPosthogReplay', {
      url: posthog?.get_session_replay_url(),
    });

    // Sentry.showReportDialog({ eventId: eventId });
    const feedback = Sentry.feedbackIntegration({
      // Disable the injection of the default widget
      autoInject: false,
    });
    // Create and render the button, then fake click on it to expand it right away (the object doesnt expose obvious method to do it)
    const widget = feedback.createWidget();
    widget.appendToDom();
    widget.show();
    widget.el.click();
    widget.removeFromDom();
  }

  showIntercomMenu() {
    intercom('show');
    this.closeSidebar.emit();
  }
}
