import { Component, HostBinding, input } from '@angular/core';

import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-card-badge',
  imports: [IconComponent],
  template: `<smw-icon [icon]="icon()" />`,
  styleUrls: ['./card-badge.component.less'],
})
export class CardBadgeComponent {
  icon = input.required<Icon>();
  disabled = input(false);

  @HostBinding('class.disabled') get disabledClass(): boolean {
    return !!this.disabled();
  }

  @HostBinding('style.background-color') get backgroundColor() {
    if (this.disabled()) {
      return 'var(--primary-50)';
    }

    return 'var(--secondary-green-100)';
  }
}
