import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of } from 'rxjs';

import { User } from '@smw/common-models';
import { BusinessServiceDto, BusinessServicesQueryDto } from '@smw/common-rest-dtos';
import { NotificationService } from '@smw/ui-front';
import { HttpParamsBuilder } from '@smw/utils-front';

import { AuthenticationService, Registration } from './authentication.service';

@Injectable()
export class RegisterService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private notifier: NotificationService,
    private translateService: TranslateService,
  ) {}

  register(registration: Registration): Observable<User | null> {
    return this.authService.register(registration).pipe(
      catchError((error) => {
        if (error.status === 409) {
          this.notifier.error({
            message: this.translateService.instant('error-409-sign-up') as string,
          });
        }

        throw error;
      }),
    );
  }

  getBusinessServices(companyCode: string | undefined): Observable<BusinessServiceDto[]> {
    if (!companyCode) {
      return of([]);
    }

    return this.http.get<BusinessServiceDto[]>('/api/business-services', {
      params: new HttpParamsBuilder<BusinessServicesQueryDto>()
        .append('company', companyCode)
        .build(),
    });
  }
}
