/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/angular';
import posthog from 'posthog-js';

let isSentryFeedbackVisible = false;
// Sentry feedback is just out of beta and a few things are still a bit missing documentation
// https://docs.sentry.io/product/user-feedback/
// This thread https://github.com/getsentry/sentry/discussions/60134 mention several info like for example how to send webhooks from alerts and include context data
export async function showSentryFeedback() {
  if (isSentryFeedbackVisible) {
    return;
  }

  isSentryFeedbackVisible = true;
  const lastReplayUrl = posthog?.get_session_replay_url();
  Sentry.setTag('latest_posthog_replay', lastReplayUrl);
  Sentry.setContext('lastPosthogReplay', {
    url: posthog?.get_session_replay_url(),
  });

  // Sentry.showReportDialog({ eventId: eventId });
  const feedback = Sentry.feedbackIntegration({
    // Disable the injection of the default widget
    autoInject: false,
    onFormSubmitted: (feedback: unknown) => {
      console.log('Feedback submitted:', feedback);
      isSentryFeedbackVisible = false;
    },
  });

  const formPromise = feedback.createForm({
    onFormClose: () => {
      isSentryFeedbackVisible = false;
    },
    onFormSubmitted: () => {
      isSentryFeedbackVisible = false;
    },
  });

  const form = await formPromise;
  form.appendToDom();
  form.open();
}
