export const AUTH_ROUTES = {
  base: 'authentification',
  login: 'se-connecter',
  loginAs: 'login-as',
  register: 'creer-un-compte',
  lostPassword: 'mot-de-passe-oublie',
  resetPassword: 'reinitialiser-mot-de-passe',
  activate: 'activation/:token',
} as const;

export const INTROSPECTION_ROUTES = {
  questionnaire: 'workoscope',
  profile: {
    base: 'mon-profil-workoscope',
    summary: '',
    synthese: 'synthese',
    details: 'details',
  } as const,
} as const;

export const CHECKUP_ROUTES = {
  base: 'check-up',
  success: 'success',
} as const;

export const DISCOVER_PROFILE_ROUTES = {
  base: 'profil',
  takeSurvey: 'take-survey',
};

export const OBJECTIVES_ROUTES = {
  base: 'suivi-de-performance',
  edition: ':id',
};

export const CONVERSATIONS_ROUTES = {
  base: 'conversations',
  conversation: {
    base: ':conversationId',
    objectives: OBJECTIVES_ROUTES,
    checkup: CHECKUP_ROUTES,
    profile: DISCOVER_PROFILE_ROUTES,
    resource: 'ressource/:step',
    preparationQuestionnaire: 'pre-interview-survey',
    lead: 'lead-interview',
    selfAssessmentQuestionnaire: 'post-interview-survey',
    validate: 'compte-rendu',
  } as const,
} as const;

export const MOBILITY_ROUTES = {
  base: 'parcours-mobilite',
  exercise: ':index',
} as const;

export const COPILOT_ROUTES = {
  base: 'chatbot',
  feedback: 'feedback',
  conversations: 'cnv',
  trainings: 'trainingHelper',
  performance: 'performance',
} as const;

export const DASHBOARD_ROUTES = {
  base: 'tableau-de-bord',
} as const;

export const TRAINING_ROUTES = {
  base: 'trainings',
} as const;

export const COLLABORATOR_ROUTES = {
  base: 'espace-collaborateur',
  dashboard: DASHBOARD_ROUTES,
  introspection: INTROSPECTION_ROUTES,
  motivations: 'motivations',
  checkup: CHECKUP_ROUTES,
  conversations: CONVERSATIONS_ROUTES,
  copilot: COPILOT_ROUTES,
  objectives: OBJECTIVES_ROUTES,
} as const;

export const MANAGER_ROUTES = {
  base: 'espace-manager',
  conversations: CONVERSATIONS_ROUTES,
  trainings: TRAINING_ROUTES,
  objectives: OBJECTIVES_ROUTES,
} as const;

export const HR_ROUTES = {
  base: 'espace-rh',
  dashboard: 'dashboard',
  users: 'users',
  campaigns: 'campaigns',
  events: 'events',
} as const;

export const FORMATEUR_ROUTES = {
  base: 'espace-formateur',
} as const;

export const APP_ROUTES = {
  fallback: '**',
  auth: AUTH_ROUTES,
  account: 'mon-compte',
  mobility: MOBILITY_ROUTES,
  collaborator: COLLABORATOR_ROUTES,
  manager: MANAGER_ROUTES,
  hr: HR_ROUTES,
  trainings: 'trainings',
  formateurs: FORMATEUR_ROUTES,
} as const;
