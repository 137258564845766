@if (onRoot()) {
  @if (smallDevice()) {
    <div class="root mobile-wrapper">
      <img class="logo" [src]="'/assets/images/logo-smw-alt.png'" [title]="'somanyWays Logo'" />
      <div class="actions">
        <div #content id="secondaries"></div>
        <button
          smw-button
          kind="icon"
          class="menu"
          [icon]="menuIcon"
          (click)="openSidebar.emit()"
        ></button>
      </div>
    </div>
  }

  @if (mediumDevice() || largeDevice()) {
    <div class="root larger-wrapper">
      <div #content id="back"></div>
      <div class="actions">
        <div #content id="secondaries"></div>
        <div #content id="primary"></div>
      </div>
    </div>
  }
} @else {
  @if (smallDevice()) {
    <div class="child mobile-wrapper">
      <div #content id="back"></div>
      <div #content id="secondaries"></div>
    </div>
  }

  @if (mediumDevice() || largeDevice()) {
    <div class="child larger-wrapper">
      <div #content id="back"></div>
      <div class="actions">
        <div #content id="secondaries"></div>
        <div #content id="primary"></div>
      </div>
    </div>
  }
}
