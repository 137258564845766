import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, computed, HostListener, model } from '@angular/core';

import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-collapse, [collapse]',
  imports: [IconComponent],
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('collapse', [
      state('true', style({ height: '0' })),
      state('false', style({ height: '*' })),
      transition('true <=> false', animate('{{duration}}ms {{animation}}'), {
        params: {
          duration: 250,
          animation: 'ease-in',
        },
      }),
    ]),
  ],
})
export class CollapseComponent {
  collapsed = model(true);

  icon = computed(() => (this.collapsed() ? Icon.carretDown : Icon.carretUp));

  @HostListener('click', ['$event']) onClick(event: MouseEvent): void {
    event.stopImmediatePropagation();
    this.collapsed.update((collapsed) => !collapsed);
  }
}
