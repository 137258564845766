<div #progress class="progress-container">
  <div #track class="track">
    @if (showHandle()) {
      <div
        class="handle"
        (mousedown)="onDrag($event, 'mouse')"
        (touchstart)="onDrag($event, 'touch')"
      ></div>
    }
  </div>
  @if (showStepsIndicators()) {
    <div class="steps">
      @for (step of [] | range: fixedStepsCount(); track step; let index = $index) {
        <div
          class="step"
          [class.current]="currentStep() === step"
          (click)="changeProgress(index)"
        ></div>
      }
    </div>
  }
</div>
<p class="title-xxs label">{{ currentValue() }} {{ unit() }}</p>
