import { Component, computed, effect, ElementRef, input, Renderer2 } from '@angular/core';

import { Icon } from './icon';
import { IconService } from './icon.service';

/**
 * A lightweight component to display SVG icons.
 *
 * This component loads and embeds an icon in SVG format based on the provided Icon enum.
 * Icons are automatically fetched, cached and rendered inside the component.
 * The rendered icon can be customized using CSS.
 *
 * @example
 * ```ts
 * import { Icon, IconComponent } from '@smw/ui-front';
 *
 * @Component({ imports: [IconComponent], })
 * export class MyComponent {
 *  icon = Icon.check;
 * }
 * ```
 * ```html
 * <smw-icon [icon]="icon" />
 * ```
 */
@Component({
  selector: 'smw-icon',
  template: ``,
  styleUrls: ['./icon.component.less'],
  imports: [],
})
export class IconComponent {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private service: IconService,
  ) {}

  icon = input.required<Icon>();

  path = computed(() => `assets/icons/${this.icon()}.svg`);

  private loadSVG = effect(() => {
    const path = this.path();

    this.service.get(path).subscribe((svg) => {
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', svg);
    });
  });
}
