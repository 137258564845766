import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { Overlay, Position } from '../overlay/overlay';

/**
 * Add a tooltip on the element.
 */
@Directive({ standalone: true, selector: '[tooltip]' })
export class TooltipDirective implements OnDestroy {
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private overlay: Overlay,
  ) {}

  /**
   * The content to be displayed as tooltip.
   * Accepts a string or a TemplateRef for custom layout.
   */
  tooltip = input.required<string | TemplateRef<unknown> | undefined>();
  /**
   * Preferred positions to display the tooltip relative to the element.
   * By default, it follows the generic specs define on Figma.
   */
  positions = input<Position[]>([
    { x: 'start-inner', y: 'start-outer' },
    { x: 'center', y: 'start-outer' },
    { x: 'end-inner', y: 'start-outer' },
    { x: 'end-inner', y: 'end-outer' },
    { x: 'center', y: 'end-outer' },
    { x: 'start-inner', y: 'end-outer' },
  ]);

  private componentRef?: ComponentRef<TooltipComponent>;

  ngOnDestroy() {
    this.componentRef?.destroy();
    this.componentRef = undefined;
  }

  @HostListener('pointerenter') private onEnter(): void {
    if (this.componentRef) {
      return;
    }
    const tooltip = this.tooltip();

    if (!tooltip) {
      return;
    }

    const element = this.elementRef.nativeElement;

    this.componentRef = this.overlay.attachComponent(
      TooltipComponent,
      [
        { key: 'tooltip', value: tooltip },
        { key: 'show', value: true },
      ],
      {
        detachStrategies: [
          { type: 'outside-move', relativeTo: this.elementRef.nativeElement },
          { type: 'outside-click' },
          { type: 'scroll' },
        ],
        placement: {
          target: element,
          preferredPositions: this.positions(),
        },
      },
    );
    this.componentRef?.onDestroy(() => (this.componentRef = undefined));
  }
}
