import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../button/button.component';
import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-modal',
  imports: [TranslateModule, ButtonComponent, IconComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  title = input.required<string>();
  showClose = input<boolean>(true);

  cancelLabel = input<string>();
  confirmLabel = input<string>();

  close = output();
  confirm = output();

  closeIcon = Icon.close;

  onCancel(event: MouseEvent): void {
    event.stopPropagation();
    this.close.emit();
  }

  onConfirm(event: MouseEvent): void {
    event.stopPropagation();
    this.confirm.emit();
  }
}
