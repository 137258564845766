import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../button/button.component';
import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-help',
  imports: [TranslateModule, ButtonComponent, IconComponent],
  templateUrl: './button-help.component.html',
  styleUrls: ['./button-help.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonHelpComponent {
  label = input<string>();

  icon = Icon.info;
}
