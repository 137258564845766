import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  input,
  OnDestroy,
  output,
  TemplateRef,
} from '@angular/core';

import { Overlay, Position } from '../overlay/overlay';

/**
 * MenuTrigger takes care of the creation of the menu and to add it into the DOM.
 * The directive will handle the position of the target element based on the provided positions.
 * If no positions are provided, the element is positioned to be always visible in the viewport.
 */
@Directive({ selector: '[menuTriggerFor]', standalone: true })
export class MenuTriggerDirective implements OnDestroy {
  menu = input.required<TemplateRef<void>>({ alias: 'menuTriggerFor' });
  position = input<Position>({ x: 'end-outer', y: 'start-inner' });
  menuClosed = output<void>();

  private viewRef?: EmbeddedViewRef<void>;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private overlay: Overlay,
  ) {}

  ngOnDestroy(): void {
    this.viewRef?.destroy();
    this.viewRef = undefined;
  }

  @HostListener('click')
  onClick() {
    const menu = this.menu();
    if (!menu) {
      return;
    }

    if (this.viewRef) {
      return;
    }

    this.viewRef = this.overlay.attachTemplate(menu, {
      detachStrategies: [{ type: 'outside-click' }],
      placement: {
        target: this.elementRef.nativeElement,
        preferredPositions: [this.position()],
      },
    });

    this.viewRef?.onDestroy(() => {
      this.viewRef = undefined;
      this.menuClosed.emit();
    });
  }
}
