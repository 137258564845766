import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostBinding,
  input,
} from '@angular/core';

import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

type ButtonKind = 'filled' | 'filled-inversed' | 'terniary' | 'terniary-white' | 'text' | 'icon';

@Component({
  selector: 'button[smw-button], a[smw-button]',
  imports: [NgClass, IconComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  constructor(private elementRef: ElementRef<HTMLButtonElement | HTMLLinkElement>) {
    const element = this.elementRef.nativeElement;
    if (element.tagName.toLowerCase() === 'button') {
      this.elementRef.nativeElement.setAttribute('type', 'button');
    }
  }

  kind = input<ButtonKind>('filled');
  loading = input(false);
  invertLoading = input(false);
  icon = input<Icon | undefined>();

  isIconButton = computed(() => this.kind() === 'icon');
  loadingIcon = computed(() => (this.invertLoading() ? Icon.loadingInvert : Icon.loading));

  @HostBinding('class') get classes(): string {
    return this.kind();
  }
}
