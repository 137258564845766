import { Component, computed } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Claim } from '@smw/common-models';
import { UserStore } from '@smw/stores-front';

type extendedClaim = Claim & { data: { adminEmail: string; adminUserId: string } };

@Component({
  selector: 'smw-logged-as-banner',
  templateUrl: './logged-as-banner.component.html',
  styleUrls: ['./logged-as-banner.component.less'],
  imports: [TranslateModule],
})
export class LoggedAsBannerComponent {
  constructor(private userStore: UserStore) {}

  userEmail = computed(() => this.userStore.user()?.email);
  claim = computed(() => this.userStore.user()?.claim as extendedClaim);

  isAdminImpersonatingUser = computed(() => {
    const claim = this.claim();
    return claim?.data?.adminUserId;
  });

  disconnect() {
    localStorage.removeItem('authenticationToken');
    location.reload();
  }
}
