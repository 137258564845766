import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, TemplateRef } from '@angular/core';

/**
 * Display help and details to user about an element in a tooltip.
 * This component is not meant to be used directly but through [the associated directive](./tooltip.directive.ts)
 */
@Component({
  selector: 'smw-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
  imports: [NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  /**
   * The content to be displayed as tooltip.
   * Accepts a string or a TemplateRef for custom layout.
   */
  tooltip = input.required<string | TemplateRef<unknown>>();
  /** True if the tooltip should be displayed */
  show = input(false);
  /** True to disable to tooltip */
  disabled = input(false);

  position = input('top');

  isEnabled = computed(() => !this.disabled());

  templateText = computed(() => {
    const tooltip = this.tooltip();
    if (tooltip instanceof TemplateRef) {
      return tooltip;
    }

    return undefined;
  });
  text = computed(() => (!this.templateText() ? this.tooltip() : ''));
}
