@for (w of rainW; track w) {
  <smw-icon
    [icon]="icon"
    [ngStyle]="{
      transform: 'rotate(' + w.rotate + 'deg)',
      left: w.left + '%',
      'animation-duration': w.speed + 's',
      color: w.color,
    }"
  />
}
