import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  imports: [CommonModule, TranslateModule],
  selector: 'smw-auth-template',
  templateUrl: 'template.component.html',
  styleUrls: ['./template.component.less'],
})
export class AuthTemplateComponent {}
