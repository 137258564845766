import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, retry, Subject, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IconService {
  constructor(private http: HttpClient) {}

  private cache = new Map<string, string>();
  private inProgressRequests = new Map<string, Subject<string>>();

  get(path: string): Observable<string> {
    if (this.cache.has(path)) {
      return of(this.cache.get(path)!);
    }

    if (this.inProgressRequests.has(path)) {
      return this.inProgressRequests.get(path)!.pipe(take(1));
    }

    const subject = new Subject<string>();
    this.inProgressRequests.set(path, subject);

    return this.http.get(path, { responseType: 'text' }).pipe(
      take(1),
      tap((svg) => this.put(path, svg)),
      retry(3), // Retry up to three times in case of error
      catchError(() => {
        /**
         * When it fails to load the image, we return the name of the icon as fallback.
         * It is not cached so that other attempt can be done.
         */
        const svg = path.split('/').reverse()[0].replace('.svg', '');
        this.completeRequest(path, svg);
        return of(svg);
      }),
    );
  }

  private put(path: string, svg: string): void {
    this.cache.set(path, svg);
    this.completeRequest(path, svg);
  }

  private completeRequest(path: string, svg: string): void {
    if (this.inProgressRequests.has(path)) {
      this.inProgressRequests.get(path)!.next(svg);
      this.inProgressRequests.get(path)!.complete();
      this.inProgressRequests.delete(path);
    }
  }
}
