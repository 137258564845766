import { Routes } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { anonymousGuard, loggedInGuard } from '@smw/authentication-front';
import { AppLayoutComponent } from '@smw/layout-front';
import { APP_ROUTES } from '@smw/navigation-front';

export const routes: Routes = [
  {
    path: APP_ROUTES.auth.base,
    canActivate: [anonymousGuard],
    loadChildren: () => import('@smw/authentication-front').then((m) => m.routes),
  },
  { path: '', pathMatch: 'full', redirectTo: APP_ROUTES.collaborator.base },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [loggedInGuard],
    children: [
      {
        path: APP_ROUTES.account,
        data: { sidebar: true },
        loadComponent: () => import('@smw/account-front').then((m) => m.AccountPageComponent),
        title: marker('page-title-account'),
      },
      {
        path: APP_ROUTES.collaborator.base,
        loadChildren: () => import('@smw/spaces-front').then((m) => m.collaboratorRoutes),
      },
      {
        path: APP_ROUTES.manager.base,
        loadChildren: () => import('@smw/spaces-front').then((m) => m.managerRoutes),
      },
      {
        path: APP_ROUTES.hr.base,
        loadChildren: () => import('@smw/human-resources-front').then((m) => m.routes),
      },
      {
        path: APP_ROUTES.mobility.base,
        data: { checkUnavailability: true },
        loadChildren: () => import('@smw/mobility-front').then((m) => m.routes),
      },
    ],
  },
  {
    path: APP_ROUTES.trainings,
    canActivate: [loggedInGuard],
    loadChildren: () => import('@smw/training-front').then((m) => m.routes),
  },
  {
    path: APP_ROUTES.formateurs.base,
    canActivate: [loggedInGuard],
    data: { sidebar: true },
    loadChildren: () => import('@smw/formateur-front').then((m) => m.routes),
  },
  {
    path: APP_ROUTES.fallback,
    loadComponent: () => import('@smw/front-404').then((m) => m.NotFoundComponent),
    title: marker('page-404-title'),
  },
];
