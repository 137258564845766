import {
  ChangeDetectionStrategy,
  Component,
  computed,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { COLLABORATOR_ROUTES, RoutePipe } from '@smw/navigation-front';
import { UserStore } from '@smw/stores-front';
import {
  ButtonComponent,
  Icon,
  IconComponent,
  PasswordComponent,
  SuccessPageComponent,
  TextFieldComponent,
} from '@smw/ui-front';

import { AuthTemplateComponent } from '../../components/template.component';
import { passwordRegexp } from '../../helpers/password-strengh';
import { AuthenticationService } from '../../services';
import { RegisterService } from '../../services/register.service';

type RegisterForm = FormGroup<{
  password: FormControl<string>;
  rgpd: FormControl<boolean>;
  newsletterConsent: FormControl<boolean>;
  lastname: FormControl<string>;
  firstname: FormControl<string>;
}>;

@Component({
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    RouterLink,
    TextFieldComponent,
    ButtonComponent,
    PasswordComponent,
    IconComponent,
    AuthTemplateComponent,
    SuccessPageComponent,
    RoutePipe,
  ],
  providers: [RegisterService],
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivationComponent implements OnInit, OnDestroy {
  activationToken: WritableSignal<string> = signal('');
  showSuccessModal = signal(false);

  form: RegisterForm = new FormGroup({
    password: new FormControl('', {
      validators: [Validators.required, Validators.pattern(passwordRegexp)],
      nonNullable: true,
    }),
    rgpd: new FormControl<boolean>(false, {
      validators: [Validators.requiredTrue],
      nonNullable: true,
    }),
    newsletterConsent: new FormControl(false, { nonNullable: true }),
    lastname: new FormControl('', {
      validators: [Validators.minLength(2)],
      nonNullable: true,
    }),
    firstname: new FormControl('', {
      validators: [Validators.minLength(2)],
      nonNullable: true,
    }),
  });

  isInvalid = signal(true);
  isLoading = signal(false);
  formRequestErrorMessage = signal('');

  hasFormRequestError = computed(() => this.formRequestErrorMessage().length > 0);
  isConfirmDisabled = computed(() => this.isLoading() || this.isInvalid());

  userFirstName = computed(() => this.userStore.firstname());

  logoIcon = Icon.logo;

  @ViewChild('firstNameInput') firstnameInput!: TextFieldComponent;
  @ViewChild('lastNameInput') lastnameInput!: TextFieldComponent;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private userStore: UserStore,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((url) => {
      this.activationToken.set(url[url.length - 1].path);
    });

    this.form.statusChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => this.isInvalid.set(status === 'INVALID'));

    this.fetchNames();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit(): void {
    this.formRequestErrorMessage.set('');

    if (this.form.invalid) {
      return;
    }

    const formValue = this.form.getRawValue();
    this.isLoading.set(true);

    this.authService
      .activate({
        activationToken: this.activationToken(),
        password: formValue.password,
        newsletterConsent: formValue.newsletterConsent,
        firstname: formValue.firstname,
        lastname: formValue.lastname,
      })
      .subscribe({
        next: () => {
          this.showSuccessModal.set(true);
        },
        error: () => {
          this.isLoading.set(false);
          this.formRequestErrorMessage.set(marker('auth_activation_error'));
        },
      });
  }

  fetchNames() {
    this.userStore.fetchNamesFromActivationToken(this.activationToken()).subscribe((data) => {
      if (data.firstname) {
        this.firstnameInput.setDisabledState(true);
        this.firstnameInput.writeValue(data.firstname);
      } else {
        this.form.controls.firstname.addValidators([Validators.required]);
        this.form.controls.firstname.updateValueAndValidity();
      }

      if (data.lastname) {
        this.lastnameInput.setDisabledState(true);
        this.lastnameInput.writeValue(data.lastname);
      } else {
        this.form.controls.lastname.addValidators([Validators.required]);
        this.form.controls.lastname.updateValueAndValidity();
      }
    });
  }

  redirectToHome() {
    this.router.navigate([COLLABORATOR_ROUTES.base]);
  }
}
