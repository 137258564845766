import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  output,
  Renderer2,
} from '@angular/core';

import { Icon } from '../icon';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-side-panel',
  imports: [IconComponent],
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [style({ transform: 'translateX(100%)' }), animate(200)]),
    ]),
  ],
})
export class SidePanelComponent implements OnDestroy {
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.initialOverflow = this.document.body.style.overflow;
    this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
  }

  closed = output();

  closeIcon = Icon.close;

  private initialOverflow = 'auto';

  ngOnDestroy(): void {
    this.renderer.setStyle(this.document.body, 'overflow', this.initialOverflow);
  }
}
