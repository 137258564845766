import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { deepEqual } from '@smw/common-utils';

export type RadioButtonOption<T> = {
  value: T;
  label: string;
};

@Component({
  selector: 'smw-radio-button',
  imports: [],
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RadioButtonComponent,
      multi: true,
    },
  ],
})
export class RadioButtonComponent<T = unknown> implements ControlValueAccessor {
  options = input<RadioButtonOption<T>[]>([]);
  valueKey = input<keyof T | undefined>(undefined);

  selectedOption = signal<RadioButtonOption<T> | undefined>(undefined);

  onChange!: (value: unknown) => void;
  onTouched!: () => void;
  onValidationChange!: () => void;

  writeValue(value: unknown): void {
    const options = this.options();

    if (!options.length) {
      return;
    }

    if (value === undefined) {
      this.selectedOption.set(undefined);
      return;
    }

    if (value === null) {
      this.selectedOption.set(options.find((item) => item.value === null));
      return;
    }

    const valueKey = this.valueKey();
    let option: RadioButtonOption<T> | undefined = undefined;

    if (valueKey) {
      option = options.find((item) => item.value[valueKey] === value);
    } else {
      option = options.find((item) => deepEqual(item.value, value));
    }

    if (option) {
      this.selectedOption.set(option);
    }

    if (!this.selectedOption()) {
      this.selectedOption.set({ label: '', value: value as T });
    }
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  changeValue(option: RadioButtonOption<T>): void {
    this.onTouched();
    this.selectedOption.set(option);

    const valueKey = this.valueKey();

    setTimeout(() => {
      this.onChange(valueKey ? option.value[valueKey] : option.value);
    }, 20);
  }
}
