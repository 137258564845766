import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  Renderer2,
  signal,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'smw-textarea',
  imports: [],
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextareaComponent,
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor, AfterViewInit {
  label = input('');
  placeholder = input('');

  @ViewChild('input', { static: true, read: ElementRef })
  textareaField!: ElementRef<HTMLTextAreaElement>;
  private textareaElement?: HTMLTextAreaElement;

  inputId?: string;
  value = signal('');

  onChange!: (value: string) => void;
  onTouched!: () => void;
  onValidationChange!: () => void;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.textareaElement = this.textareaField.nativeElement;
  }

  writeValue(value: string): void {
    this.value.set(value);
    this.renderer.setProperty(this.textareaField.nativeElement, 'value', value ?? '');
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.textareaField) {
      this.renderer.setProperty(this.textareaField.nativeElement, 'disabled', isDisabled);
    }
  }

  updateValue(value: string): void {
    if (!this.textareaElement) {
      return;
    }

    const currentHeight = this.textareaElement?.clientHeight;
    const scrollHeight = this.textareaElement?.scrollHeight;
    const style = getComputedStyle(this.textareaElement);
    const fontSize = +style.fontSize.replace('px', '');
    const minHeight = +style.minHeight.replace('px', '');

    if (scrollHeight <= currentHeight && currentHeight > minHeight) {
      this.renderer.setStyle(this.textareaElement, 'height', scrollHeight - fontSize + 'px');
    }

    if (scrollHeight > currentHeight) {
      this.renderer.setStyle(this.textareaElement, 'height', scrollHeight + 'px');
    }

    this.onChange(value);
  }
}
