<smw-auth-template>
  <div class="main">
    <smw-icon class="logo" [icon]="logoIcon" />

    <h1 class="title-md mb-3">{{ 'auth_login_title' | translate }}</h1>
    <p class="body-lg mb-8">{{ 'auth_login_subtitle' | translate }}</p>

    @if (form) {
      <form [formGroup]="form">
        <smw-textfield
          class="mb-6"
          style="font-family: Nexa; font-weight: 300"
          formControlName="email"
          [type]="'email'"
          [placeholder]="'input-email-placeholder' | translate"
          [invalid]="isEmailInvalid() || hasFormError()"
        />

        @if (isEmailInvalid()) {
          <p class="caption-sm text-secondary-red-500 email-error-message">
            {{ 'auth_login_email_error' | translate }}
          </p>
        }

        <smw-password
          class="mb-4"
          formControlName="password"
          [placeholder]="'input-password-placeholder' | translate"
          [invalid]="hasFormError()"
        />

        @if (formRequestErrorMessage) {
          <p class="caption-sm text-secondary-red-500">
            {{ formRequestErrorMessage | translate }}
          </p>
        }

        <a class="link-md mb-3" [routerLink]="['auth.lostPassword' | route]">
          {{ 'auth_login_lost-password' | translate }}
        </a>
      </form>
    }
  </div>

  <div class="actions">
    <button smw-button kind="filled" class="mb-10" (click)="submit()">
      {{ 'auth_login_action' | translate }}
    </button>
  </div>
</smw-auth-template>
