import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, of } from 'rxjs';

import { ButtonComponent, NotificationService } from '@smw/ui-front';

import { AuthTemplateComponent } from '../../components/template.component';

@Component({
  selector: 'smw-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.less'],
  imports: [
    CommonModule,
    FormsModule,
    AuthTemplateComponent,
    TranslateModule,
    ReactiveFormsModule,
    ButtonComponent,
  ],
})
export class LoginAsPageComponent implements OnInit {
  inputAdminEmail = '';
  inputAdminPassword = '';
  inputUserEmail = '';

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
  ) {}
  ngOnInit(): void {
    this.inputUserEmail = this.activatedRoute.snapshot.queryParamMap.get('userEmail') ?? '';
  }

  connectAs() {
    localStorage.removeItem('authenticationToken');

    this.http
      .post(
        '/api/admin/auth/login-as',
        {
          userEmail: this.inputUserEmail,
          adminEmail: this.inputAdminEmail,
          adminPassword: this.inputAdminPassword,
        },
        { responseType: 'text' as 'json' },
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          this.notificationService.error({ message: err.message });
          return of(null);
        }),
      )
      .subscribe((res) => {
        if (res && typeof res === 'string') {
          localStorage.setItem('authenticationToken', res);
          window.location.href = '/';
        }
      });
  }
}
