import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { Breakpoint } from '../helpers/breakpoints';
import { BreakpointsService } from '../services';

@Directive({ selector: '[breakpoints]', standalone: true })
export class BreakpointDirective implements OnInit, OnDestroy {
  @Input() breakpoints!: Breakpoint[];
  @Input() breakpointOnly = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private breakpointsService: BreakpointsService,
    private templateRef: TemplateRef<Element>,
    private vcr: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.breakpointsService
      .matches(this.breakpoints, this.breakpointOnly)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((match) => {
        if (match) {
          this.vcr.createEmbeddedView(this.templateRef);
        } else {
          this.vcr.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
