import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, throwError } from 'rxjs';

import { BackComponent, RoutePipe } from '@smw/navigation-front';
import { ButtonComponent, Icon, IconComponent, TextFieldComponent } from '@smw/ui-front';

import { AuthTemplateComponent } from '../../components/template.component';
import { AuthenticationService } from '../../services';

@Component({
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    RouterLink,
    ButtonComponent,
    TextFieldComponent,
    AuthTemplateComponent,
    IconComponent,
    BackComponent,
    RoutePipe,
  ],
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LostPasswordComponent implements OnInit {
  form?: FormGroup<{ email: FormControl<string> }>;
  emailSent = false;
  isProcessing = false;
  resetPasswordErrorMessage = signal('');
  isEmailInvalid = signal(false);

  logoIcon = Icon.logo;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
  ) {}

  get isDisabled(): boolean {
    return this.form?.invalid || this.isProcessing;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email, this.validateEmailDomain()],
        nonNullable: true,
      }),
    });

    this.changeDetector.markForCheck();
  }

  submit(): void {
    if (this.form?.invalid) {
      return;
    }
    const { email } = this.form!.getRawValue();

    this.authenticationService
      .lostPassword(email)
      .pipe(
        catchError((error) => {
          this.resetPasswordErrorMessage.set(marker('auth_lost-password_error'));
          return throwError(() => error);
        }),
      )
      .subscribe(() => {
        this.emailSent = true;
        this.isProcessing = false;
        this.changeDetector.markForCheck();
      });
  }

  validateEmailDomain(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.isEmailInvalid.set(false);
      const pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      let email = control.value as string;
      email = email.replace(' ', '');

      if (email.length === 0) {
        return null;
      }

      const isValid = pattern.test(email);

      this.isEmailInvalid.set(!isValid);

      return !isValid ? { invalidDomain: { value: control.value } } : null;
    };
  }
}
