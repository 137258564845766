import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  EnvironmentInjector,
  Inject,
  Injectable,
  InputSignal,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  TemplateRef,
  Type,
} from '@angular/core';
import {
  BehaviorSubject,
  filter,
  fromEvent,
  Observable,
  of,
  race,
  skip,
  Subject,
  takeUntil,
  timer,
} from 'rxjs';

import { OutsideClickListener } from '@smw/utils-front';

import { OverlayComponent } from './overlay.component';

export type Ref<T> = ComponentRef<T> | EmbeddedViewRef<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputKeys<T> = { [K in keyof T]: T[K] extends InputSignal<any> ? K : never }[keyof T];
type InputValueType<T, K extends keyof T> = T[K] extends InputSignal<infer U> ? U : never;
export type InputKeyValuePair<T> = {
  [K in InputKeys<T>]: { key: K; value: InputValueType<T, K> };
}[InputKeys<T>];

type DetachStrategy =
  | ManualDetachStrategy
  | OutsideModeDetachStrategy
  | OutsideClickDetachStrategy
  | ScrollDetachStrategy
  | TimeoutDetachStrategy;

/** The caller is responsible of detaching the element from the overlay. */
type ManualDetachStrategy = {
  type: 'manual';
};

/** The element will be removed from the overlay when the cursor is moved outside of its relative. */
type OutsideModeDetachStrategy = {
  type: 'outside-move';
  relativeTo: HTMLElement;
};

/** The element will be removed from the overlay on scroll */
type ScrollDetachStrategy = {
  type: 'scroll';
};

/** The element will be removed from the overlay when a click occurs outside of the element */
type OutsideClickDetachStrategy = {
  type: 'outside-click';
};

/** The element will be removed from the overlay after a given time  */
type TimeoutDetachStrategy = {
  type: 'timeout';
  timeout: number;
};

type Alignment = 'start-outer' | 'start-inner' | 'center' | 'end-inner' | 'end-outer';

/**
 * Y alignements representation:
 *
 *   start-outer
 * ┌─────────────┐
 * │ start-inner │
 * │             │
 * │ center      │
 * │             │
 * │ end-inner   │
 * └─────────────┘
 *   end-outer
 *
 * X alignements representation:
 *             ┌──────────────────────────────────────┐
 * start-outer │ start-inner     center     end-inner │ end-outer
 *             └──────────────────────────────────────┘
 *
 *
 * All combinations for positioning an element A relative to a target T.
 * In this sketch, T is represented by the box.
 * Each x,y couple is a possible position for A
 *
 *
 * x: start-outer    │ x: start-inner            x: center                   x: end-inner │ x: end-outer
 * y: start-outer    │ y: start-outer            y: start-outer            y: start-outer │ y: start-outer
 * ──────────────────┼────────────────────────────────────────────────────────────────────┼────────────────
 * x: start-outer    │ x: start-inner            x: center                   x: end-inner │ x: end-outer
 * y: start-inner    │ y: start-inner            y: start-inner            y: start-inner │ y: start-inner
 *                   │                                                                    │
 *                   │                                                                    │
 * x: start-outer    │ x: start-inner            x: center                   x: end-inner │ x: end-outer
 * y: center         │ y: center                 y: center                      y: center │ y: center
 *                   │                                                                    │
 *                   │                                                                    │
 * x: start-inner    │ x: start-inner            x: center                   x: end-inner │ x: end-outer
 * y: end-inner      │ y: end-inner              y: end-inner                y: end-inner │ y: end-inner
 * ──────────────────┼────────────────────────────────────────────────────────────────────┼───────────────
 * x: start-outer    │ x: start-inner            x: center                   x: end-inner │ x: end-outer
 * y: end-outer      │ y: end-outer              y: end-outer                y: end-outer │ y: end-outer
 *
 */
export type Position = { x: Alignment; y: Alignment };
type Placement = {
  target: HTMLElement;
  preferredPositions: Position[];
};
type Coordinates = { left: number; top: number };

type AttachOption = {
  detachStrategies: DetachStrategy[];
  placement: Placement;
};

function pxToRem(px: number): number {
  const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
  return px / rootFontSize;
}

/**
 * Manage overlay components like tooltips, modals, popups, etc...
 * When such components are required, an invisible relative overlay is added to the view
 * on which these components will be added .
 *
 * - Supports dynamic component & template attachment.
 * - Ensures elements fit within the viewport.
 * - Provides multiple detachment strategies (timeout, outside-click, scroll, manual, etc.).
 * - Handles resizing dynamically.
 */
@Injectable({ providedIn: 'root' })
export class Overlay implements OnDestroy {
  constructor(
    rendererFactory: RendererFactory2,
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector,
    private outsideClickListener: OutsideClickListener,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.renderer = rendererFactory.createRenderer(undefined, null);
  }

  private renderer: Renderer2;
  private overlayRef?: ComponentRef<OverlayComponent>;

  private attachedRefs$ = new BehaviorSubject<Set<Ref<unknown>>>(new Set());

  private resizeObserver?: ResizeObserver;

  private unsubscribe$ = new Subject<void>();
  private isDestroying = false;

  ngOnDestroy(): void {
    this.destroy();
  }

  /**
   * Dynamically add a component to the overlay.
   * The overlay is automatically instantiated if required.
   *
   * @param component The Angular component to attach.
   * @param inputs Inputs expected by the component.
   * @param options Configuration including placement and detachment strategies. See {@link AttachOption | Attach Options}.
   * @returns The created component reference, or `undefined` if attachment failed.
   */
  attachComponent<T>(
    component: Type<T>,
    inputs: InputKeyValuePair<T>[],
    options: AttachOption,
  ): ComponentRef<T> | undefined {
    const overlayRef = this.getOrCreateOverlayRef();

    const vcr = overlayRef.instance.containerRef();
    if (!vcr) {
      return;
    }

    const componentRef = vcr.createComponent(component, { environmentInjector: this.injector });
    inputs.forEach((input) => componentRef.setInput(input.key as string, input.value));

    /**
     * Allow to trigger change detections in component and setup view before positioning the items,
     * as the element may not have its full size before complete initialization.
     **/
    componentRef.changeDetectorRef.detectChanges();

    this.setupDetachStrategies(componentRef, options.detachStrategies);
    this.setElementPosition(componentRef.location.nativeElement, options.placement);

    this.trackAttachedRef(componentRef);

    return componentRef;
  }

  /**
   * Dynamically add an Angular template to the overlay.
   * The overlay is automatically instantiated if required.
   *
   * @param component The Angular template reference to attach.
   * @param options Configuration including placement and detachment strategies. See {@link AttachOption | Attach Options}.
   * @returns The created embedded view reference, or `undefined` if attachment failed.
   */
  attachTemplate<T = unknown>(
    template: TemplateRef<T>,
    options: AttachOption,
  ): EmbeddedViewRef<T> | undefined {
    const overlayRef = this.getOrCreateOverlayRef();

    const vcr = overlayRef.instance.containerRef();
    if (!vcr) {
      return;
    }

    const viewRef = vcr.createEmbeddedView(template);
    const element = viewRef.rootNodes[0];

    this.setupDetachStrategies(viewRef, options.detachStrategies);
    this.setElementPosition(element, options.placement);
    this.observeResizeOf(element, options.placement);

    this.trackAttachedRef(viewRef);

    return viewRef;
  }

  /**
   * Destroy the overlay and all attached elements
   **/
  destroy(): void {
    if (!this.overlayRef || this.isDestroying) {
      return;
    }

    this.isDestroying = true;

    this.attachedRefs$.value.forEach((value) => value?.destroy());

    this.renderer.removeChild(this.document.body, this.overlayRef?.location.nativeElement);
    this.overlayRef.destroy();
    this.overlayRef = undefined;
    this.resizeObserver?.disconnect();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.isDestroying = false;
  }

  private setupDetachStrategies(ref: Ref<unknown>, strategies: DetachStrategy[]): void {
    if (!strategies.length) {
      return;
    }

    const detach$ = new Subject<void>();
    let element: HTMLElement | undefined;

    if (ref instanceof ComponentRef) {
      element = ref.location.nativeElement;
    } else {
      element = ref.rootNodes[0];
    }

    if (!element) {
      return;
    }

    ref.onDestroy(() => {
      // Trigger the subject to close the subscription if the ref is destroyed before there is an actual outside click
      this.untrackAttachRef(ref);
      detach$.next();
    });

    const detachObservers = strategies.map((strategy) => {
      switch (strategy.type) {
        case 'scroll':
          return this.observeScroll().pipe(takeUntil(detach$));
        case 'outside-move':
          return this.observeOutsideMove(strategy.relativeTo).pipe(takeUntil(detach$));
        case 'outside-click':
          return this.observeOutsideClick(element).pipe(takeUntil(detach$));
        case 'timeout':
          return this.observeTimeout(ref, strategy.timeout).pipe(takeUntil(detach$));
        case 'manual':
          return this.observeManualDetach(ref).pipe(takeUntil(detach$));
        default:
          return new Subject<void>();
      }
    });

    race(...detachObservers)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        ref.destroy();
        detach$.next();
        detach$.complete();
      });
  }

  private observeOutsideMove(relativeTo: HTMLElement): Observable<PointerEvent> {
    return fromEvent<PointerEvent>(this.document.documentElement, 'pointermove').pipe(
      filter((event) => this.isOutsideOf(relativeTo, event)),
    );
  }

  private observeScroll(): Observable<PointerEvent | undefined> {
    const element = this.document.getElementsByClassName('app-content')?.[0];
    if (!element) {
      return of(undefined);
    }

    return fromEvent<PointerEvent>(element, 'scroll');
  }

  private observeOutsideClick(element: HTMLElement): Observable<PointerEvent> {
    return this.outsideClickListener.clickedOutsideOf(element).pipe(skip(1));
  }

  private observeTimeout(ref: Ref<unknown>, timeout = 10_000): Observable<0> {
    return timer(timeout);
  }

  private observeManualDetach(ref: Ref<unknown>): Observable<void> {
    return new Observable((observer) => {
      ref.onDestroy(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  private isOutsideOf(element: HTMLElement, event: PointerEvent) {
    const rect = element.getBoundingClientRect();
    const pointerX = event.clientX;
    const pointerY = event.clientY;
    return !(
      pointerX >= rect.left &&
      pointerX <= rect.right &&
      pointerY >= rect.top &&
      pointerY <= rect.bottom
    );
  }

  private getOrCreateOverlayRef(): ComponentRef<OverlayComponent> {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const ref = createComponent(OverlayComponent, { environmentInjector: this.injector });
    this.renderer.appendChild(this.document.body, ref.location.nativeElement);
    this.appRef.attachView(ref.hostView);

    this.overlayRef = ref;

    /** Destroy the container element when there is no more attached views. */
    this.attachedRefs$.pipe(skip(1), takeUntil(this.unsubscribe$)).subscribe((refs) => {
      if (refs.size === 0) {
        this.destroy();
      }
    });
    return ref;
  }

  private trackAttachedRef(ref: Ref<unknown>): void {
    this.attachedRefs$.next(this.attachedRefs$.value.add(ref));
  }

  private untrackAttachRef(ref: Ref<unknown>): void {
    const refs = this.attachedRefs$.value;
    refs.delete(ref);
    this.attachedRefs$.next(refs);
  }

  /**
   * Sets the position of an element relative to its target.
   * Tries preferred positions first, then calculates a fallback if needed.
   *
   * @param element The element to position.
   * @param placement The target element and preferred positions.
   */
  private setElementPosition(element: HTMLElement, placement: Placement): void {
    const viewportHeight = this.document.documentElement.offsetHeight;
    const viewportWidth = this.document.documentElement.offsetWidth;

    const targetRect = placement.target.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    let candidate: Coordinates | undefined;
    let index = 0;

    while (index < placement.preferredPositions.length && !candidate) {
      const coordinates = this.calculateCoordinates(
        targetRect,
        elementRect,
        placement.preferredPositions[index],
      );

      if (this.checkViewportFitting(coordinates, elementRect, viewportHeight, viewportWidth)) {
        candidate = coordinates;
      }

      ++index;
    }

    if (!candidate) {
      const x = this.findHorizontalFallback(targetRect, elementRect, viewportWidth);
      const y = this.findVerticalFallback(targetRect, elementRect, viewportHeight);
      candidate = this.calculateCoordinates(targetRect, elementRect, { x, y });
    }

    this.renderer.setStyle(element, 'position', 'absolute');
    this.renderer.setStyle(element, 'top', `${pxToRem(candidate.top)}rem`);
    this.renderer.setStyle(element, 'left', `${pxToRem(candidate.left)}rem`);
  }

  /** Calculate the `Coordinates` of the element in pixels for a given position  */
  private calculateCoordinates(
    targetRect: DOMRect,
    elementRect: DOMRect,
    { x, y }: Position,
  ): Coordinates {
    let left: number;
    let top: number;

    switch (x) {
      case 'start-outer':
        left = targetRect.left - elementRect.width;
        break;
      case 'start-inner':
        left = targetRect.left;
        break;
      case 'center':
        left = targetRect.left + (targetRect.width - elementRect.width) / 2;
        break;
      case 'end-inner':
        left = targetRect.right - elementRect.width;
        break;
      case 'end-outer':
        left = targetRect.right;
        break;
      default:
        left = targetRect.left;
        break;
    }

    switch (y) {
      case 'start-outer':
        top = targetRect.top - elementRect.height;
        break;
      case 'start-inner':
        top = targetRect.top;
        break;
      case 'center':
        top = targetRect.top + (targetRect.height - elementRect.height) / 2;
        break;
      case 'end-inner':
        top = targetRect.bottom - elementRect.height;
        break;
      case 'end-outer':
        top = targetRect.bottom;
        break;
      default:
        top = targetRect.top;
        break;
    }

    return { left, top };
  }

  private checkViewportFitting(
    { top, left }: Coordinates,
    elementRect: DOMRect,
    viewportHeight: number,
    viewportWidth: number,
  ): boolean {
    return (
      left >= 0 &&
      left + elementRect.width <= viewportWidth &&
      top >= 0 &&
      top + elementRect.height <= viewportHeight
    );
  }

  private findHorizontalFallback(
    targetRect: DOMRect,
    elementRect: DOMRect,
    viewportWidth: number,
  ): Alignment {
    const enoughPlaceOnRight = viewportWidth - targetRect.right > elementRect.width;
    const enoughPlaceOnLeft = elementRect.width < targetRect.left;
    if (enoughPlaceOnRight) {
      return 'end-outer';
    } else if (enoughPlaceOnLeft) {
      return 'start-outer';
    } else {
      return 'start-inner';
    }
  }

  private findVerticalFallback(
    targetRect: DOMRect,
    elementRect: DOMRect,
    viewportHeight: number,
  ): Alignment {
    const enoughPlaceOnBottom = viewportHeight - targetRect.top > elementRect.height;
    const enoughPlaceOnTop = elementRect.height < targetRect.top;
    if (enoughPlaceOnBottom) {
      return 'end-outer';
    } else if (enoughPlaceOnTop) {
      return 'start-outer';
    } else {
      return 'start-inner';
    }
  }

  /**
   * When adding to the viewport, the element may not have its final height,
   * for example when the elements are loaded asynchronously. In this case, the position may be off.
   *
   * This method allows to check if there is a resize of the menu.
   * We only have to check once for the initial population of the menu,
   * thus we unobserve as soon as the reposition is done.
   */
  private observeResizeOf(element: HTMLElement, placement: Placement) {
    this.resizeObserver = new ResizeObserver(() => {
      this.setElementPosition(element, placement);
      this.destroyResizeObserver();
    });

    this.resizeObserver.observe(element);
  }

  private destroyResizeObserver(): void {
    this.resizeObserver?.disconnect();
  }
}
