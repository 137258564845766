<div class="backdrop" (click)="onCancel($event)"></div>
<div class="modal-overlay">
  <header>
    <p class="title-sm text-black">{{ title() }}</p>
    @if (showClose()) {
      <button type="button" (click)="onCancel($event)">
        <smw-icon [icon]="closeIcon" />
      </button>
    }
  </header>

  <div class="content">
    <ng-content />
  </div>

  <footer>
    <button smw-button kind="terniary" (click)="onCancel($event)">
      {{ cancelLabel() ?? ('cancel' | translate) }}
    </button>
    <button smw-button kind="filled" (click)="onConfirm($event)">
      {{ confirmLabel() ?? ('confirm' | translate) }}
    </button>
  </footer>
</div>
