import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '@smw/common-models';
import { UpdateUserDto } from '@smw/common-rest-dtos';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  patch(payload: UpdateUserDto): Observable<User> {
    return this.http.patch<User>(`/api/user`, payload);
  }
}
