@if (icon(); as icon) {
  <smw-icon [icon]="icon" />
}

@if (!isIconButton()) {
  @if (loading()) {
    <smw-icon [icon]="loadingIcon()" class="!w-14 absolute" />
  }
  <div [ngClass]="loading() ? 'opacity-0' : ''">
    <ng-content />
  </div>
}
