import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  Renderer2,
} from '@angular/core';

import { stringToHSL } from '@smw/utils-front';

/**
 * Display an avatar representing a user.
 */
@Component({
  selector: 'smw-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.less'],
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.renderer.addClass(this.elementRef.nativeElement, 'body-sm');
  }

  /** The fullname of the user. The first letter will be displayed in the avatar. */
  fullname = input.required<string>();
  /** If true, the color of the avatar will be derived from the fullname. Otherwise, the color will be primary blue. */
  adaptative = input(false);

  initial = computed(() => this.fullname()?.[0] ?? 'A');

  changeColor = effect(() => {
    if (this.adaptative()) {
      const hsl = stringToHSL(this.fullname());
      this.renderer.setStyle(this.elementRef.nativeElement, 'background-color', hsl);
    }
  });
}
