import { ModuleStepType } from '@smw/common-rest-dtos';

export type AnalyticsEvent =
  | PageOpened
  | PageClosed
  | SignedUp
  | SignedIn
  | PasswordLost
  | DocumentDownloaded
  | MotivationDone
  | CheckUpDone
  | TestCompleted
  | SolutionAddedToFavorites
  | SolutionFiltered
  | SolutionLinkOpened
  | ConversationCreated
  | ConversationOpened
  | ConversationEdited
  | ConversationUpdated
  | ConversationShared
  | ConversationStepDone
  | ChatbotMessageSent
  | HRDashboardOpened
  | TrainingStepCompleted
  | TrainingStatusChange;

// todo: unsure what this is used for, refactor
export type EventId = AnalyticsEvent['id'];

export enum EventIdentifier {
  PageOpened = 'pageOpened',
  PageClosed = 'pageClosed',
  PasswordLost = 'passwordLost',
  DocumentDownloaded = 'documentDownloaded',
  TestCompleted = 'testCompleted',
  MotivationDone = 'motivationDone',
  CheckUpDone = 'checkUpDone',
  UserSignedUp = 'userSignedUp',
  UserSignedIn = 'userSignedIn',
  SolutionAddedToFavorites = 'solutionAddedToFavorites',
  SolutionLinkOpened = 'solutionLinkOpened',
  SolutionFiltered = 'solutionFiltered',
  ConversationCreated = 'conversationCreated',
  ConversationOpened = 'conversationOpened',
  ConversationEdited = 'conversationEdited',
  ConversationUpdated = 'conversationUpdated',
  ConversationShared = 'conversationShared',
  ConversationStepDone = 'conversationStepDone',
  ChatbotMessageSent = 'chatbotMessageSent',
  HRDashboardOpened = 'hrDashboardOpened',
  TrainingStepCompleted = 'trainingStepCompleted',
  TrainingStatusChange = 'trainingStatusChange',
}

export type PageOpened = {
  id: EventIdentifier.PageOpened;
  data: {
    url: string;
    previousUrl: string;
  };
};

export type PageClosed = {
  id: EventIdentifier.PageClosed;
  data: {
    url: string;
  };
};

type PasswordLost = {
  id: EventIdentifier.PasswordLost;
  data: {
    email: string;
  };
};

type DocumentDownloaded = {
  id: EventIdentifier.DocumentDownloaded;
  data: {
    page: string;
    documentName: string;
  };
};

type TestCompleted = {
  id: EventIdentifier.TestCompleted;
  data?: void;
};

type MotivationDone = {
  id: EventIdentifier.MotivationDone;
  data?: void;
};

type CheckUpDone = {
  id: EventIdentifier.CheckUpDone;
  data?: void;
};

type SignedUp = {
  id: EventIdentifier.UserSignedUp;
  data?: void;
};

type SignedIn = {
  id: EventIdentifier.UserSignedIn;
  data?: void;
};

type SolutionAddedToFavorites = {
  id: EventIdentifier.SolutionAddedToFavorites;
  data: {
    solutionId: string;
  };
};

type SolutionLinkOpened = {
  id: EventIdentifier.SolutionLinkOpened;
  data: {
    solutionId: string;
  };
};

type SolutionFiltered = {
  id: EventIdentifier.SolutionFiltered;
  data: {
    filter: 'topMotivation' | 'lowMotivation' | 'profile';
  };
};

type ConversationCreated = {
  id: EventIdentifier.ConversationCreated;
  data: {
    conversationType: string;
    recurrence: string;
  };
};

type ConversationOpened = {
  id: EventIdentifier.ConversationOpened;
  data: {
    conversationType: string;
    recurrence: string;
  };
};

type ConversationEdited = {
  id: EventIdentifier.ConversationEdited;
  data: {
    typeChanged: boolean;
    recurrenceChanged: boolean;
    dateChanged: boolean;
    attendeesChanged: boolean;
    target: 'next' | 'all';
  };
};

type ConversationUpdated = {
  id: EventIdentifier.ConversationUpdated;
  data: {
    conversationType: string;
    recurrence: string;
    withAttendees: boolean;
    section: string;
  };
};

type ConversationShared = {
  id: EventIdentifier.ConversationShared;
  data: {
    conversationType: string;
    recurrence: string;
  };
};

type ConversationStepDone = {
  id: EventIdentifier.ConversationStepDone;
  data: {
    conversationId: string;
    stepName: string;
  };
};

type ChatbotMessageSent = {
  id: EventIdentifier.ChatbotMessageSent;
  data?: void;
};

type HRDashboardOpened = {
  id: EventIdentifier.HRDashboardOpened;
  data?: void;
};

type TrainingStepCompleted = {
  id: EventIdentifier.TrainingStepCompleted;
  data: {
    trainingStepName: string;
    trainingStatusName: string;
    eventId?: string;
    stepType: ModuleStepType;
  };
};

type TrainingStatusChange = {
  id: EventIdentifier.TrainingStatusChange;
  data: {
    TrainingStatusName: string;
  };
};
