import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { ButtonComponent } from '../button/button.component';
import { RainWComponent } from '../rain-w/rain-w.component';
import { SmwBackgroundComponent } from '../smw-background/smw-background.component';

@Component({
  selector: 'smw-success',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.less'],
  imports: [SmwBackgroundComponent, RainWComponent, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessPageComponent {
  title = input.required<string>();
  message = input<string>();
  ctaLabel = input.required<string>();
  secondaryCTALabel = input<string | undefined>();

  cta = output();
  secondaryCTA = output();
}
