import { Component, computed, HostBinding, input } from '@angular/core';
import { DateTime } from 'luxon';

import { Icon, IconComponent } from '../../icon';

export type DateChipState = 'default' | 'expired' | 'finished' | 'null';

@Component({
  selector: 'smw-date-chip',
  templateUrl: 'date-chip.component.html',
  styleUrls: ['./date-chip.component.less', '../chip.less'],
  imports: [IconComponent],
})
export class DateChipComponent {
  date = input.required<DateTime>();
  state = input<DateChipState>('default');

  formattedDate = computed(() => {
    const date = this.date();
    const state = this.state();
    if (!date || state === 'null') {
      return '-';
    }

    if (state === 'finished') {
      return date.toFormat('D');
    }

    return date.toFormat('DD');
  });

  icon = computed(() => {
    switch (this.state()) {
      case 'finished':
        return Icon.check;
      case 'null':
        return Icon.time;
      case 'expired':
      case 'default':
      default:
        return Icon.agenda;
    }
  });

  @HostBinding('class') get stateClass(): string {
    return this.state();
  }
}
