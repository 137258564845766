import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { Observable, share, take, tap } from 'rxjs';

import {
  User as ApiUser,
  Claim,
  CompanyPlugins,
  JobResponsibility,
  UserModuleAccess,
  UserRole,
} from '@smw/common-models';

type Company = {
  id: string;
  name: string;
  active: boolean;
  enabledModules: CompanyPlugins[];
  mobilityRegistrationLink: string;
  subDomain: string;
};

export type User = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  roles: UserRole[];
  company: Company;
  businessServiceId: string;
  jobResponsabilities: JobResponsibility;
  newsletterConsent: boolean;
  grantedModules: UserModuleAccess[];
  isRealUser: boolean;
  claim: Claim;
};

@Injectable({ providedIn: 'root' })
export class UserStore {
  constructor(private http: HttpClient) {
    this.loadUser().subscribe();
  }

  user = signal<User | undefined>(undefined);

  company = computed(() => this.user()?.company);

  firstname = computed(() => {
    const user = this.user();
    if (!user) {
      return '';
    }

    return user.firstname;
  });
  lastname = computed(() => {
    const user = this.user();
    if (!user) {
      return '';
    }

    return user.lastname;
  });
  fullname = computed(() => `${this.firstname()} ${this.lastname()}`.trim());

  roles = computed(() => this.user()?.roles ?? []);

  isCollaborator = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }

    return user.roles.includes('collaborator');
  });

  isManager = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }

    return user.roles.includes('manager');
  });

  isHR = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }

    return user.roles.includes('hr');
  });

  // This new field is used to distinguish internal SMW user, which is slighlty different from "real user" which may also exclude spam emails etc.
  isInternalSMWUser = computed(() => {
    const user = this.user();
    if (!user) {
      return false;
    }
    return user.email.indexOf("@smw.co") != -1 || user.email.indexOf("@somanyways.co") != -1;
  })

  companyEnablesModules = computed(() => {
    const company = this.company();
    if (!company) {
      return [];
    }

    return company.enabledModules;
  });
  grantedModules = computed(() => {
    const user = this.user();
    if (!user) {
      return [];
    }

    return user.grantedModules;
  });

  loadUser(): Observable<ApiUser> {
    return this.http.get<ApiUser>('/api/user').pipe(
      take(1),
      tap((user) => {
        if (user) {
          this.user.set({
            id: user.id,
            firstname: user.firstname,
            lastname: user.lastname ?? '',
            roles: user.roles,
            email: user.email,
            businessServiceId: user.businessServiceId ?? '',
            jobResponsabilities: user.jobResponsabilities ?? JobResponsibility.OTHER,
            newsletterConsent: user.newsletterConsent ?? false,
            isRealUser: user.isRealUser ?? true,
            company: {
              id: user.company!.id,
              active: user.company?.active ?? true,
              enabledModules: user.company!.plugins,
              name: user.company!.name,
              mobilityRegistrationLink: user.company!.mobilityRegistration ?? '',
              subDomain: user.company?.code ?? 'undefined',
            },
            grantedModules: user.moduleAccess,
            claim: user.claim as Claim,
          });
        }
      }),
      share(),
    );
  }

  clear(): void {
    this.user.set(undefined);
  }

  fetchNamesFromActivationToken(
    token: string,
  ): Observable<{ firstname: string; lastname: string }> {
    return this.http.get<{ firstname: string; lastname: string }>(`/api/activation/get-names`, {
      params: {
        token,
      },
    });
  }
}
