<header [class.open]="!collapsed()">
  <ng-content select="[collapse-title]" />
  <smw-icon [icon]="icon()" />
</header>

<div class="collapsible" [@collapse]="collapsed() ? true : false">
  <div class="body">
    <ng-content select="[collapse-body]" />
  </div>
</div>
