@if (label(); as label) {
  <label [htmlFor]="inputId" class="caption-sm">{{ label }}</label>
}
<div class="box" [class.invalid]="invalid()">
  <input
    #input
    class="body-md"
    autocomplete="current-password"
    [type]="inputType()"
    [attr.id]="inputId"
    [value]="value()"
    [placeholder]="placeholder()"
    (change)="onChange($any($event.target).value)"
  />
  <smw-icon [icon]="icon()" (click)="changePasswordVisibility()" />
</div>
