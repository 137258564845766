<smw-icon [icon]="icon" class="mobile" />

<button
  smw-button
  kind="terniary"
  class="large"
  [title]="label() || ('navigation-header_help' | translate)"
>
  {{ label() || ('navigation-header_help' | translate) }}
</button>
