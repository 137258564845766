import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Breakpoint, toMediaQuery } from '../helpers';

@Injectable({ providedIn: 'root' })
export class BreakpointsService {
  constructor(private breakpointObserver: BreakpointObserver) {}

  matches(breakpoints: Breakpoint[], breakpointOnly = false): Observable<boolean> {
    return this.breakpointObserver
      .observe(toMediaQuery(breakpoints, breakpointOnly))
      .pipe(map((result) => result.matches));
  }
}
