import { Location } from '@angular/common';
import { Component, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent, Icon } from '@smw/ui-front';
import { BreakpointDirective } from '@smw/utils-front';

import { HistoryService } from './history.service';
import { NavService } from './nav.service';

/**
 * Back Button Component
 *
 * Displays a back button that intelligently handles navigation within the app.
 * The button supports different navigation behaviors based on the available history state.
 *
 * ## Behavior:
 * 1. **Override Path (`override`)**: If provided, the button always navigates to this path.
 * 2. **First Page in App**: If no in-app history exists, it navigates to `default` to prevent returning to an external site.
 * 3. **Previous Page in App**: If the user has visited other pages within the app,
 *  it navigates back using `location.back()`, attempting browser-like back navigation
 *
 * ## Usage Examples:
 * ```html
 * <smw-back />
 * <smw-back [default]="'..'" [isRelative]="true" />
 * <smw-back [default]="'collaborator.dashboard' | route" />
 * <smw-back [override]="backUrl()" />
 * ```
 * @component
 */
@Component({
  selector: 'smw-back',
  imports: [TranslateModule, BreakpointDirective, ButtonComponent],
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.less'],
})
export class BackComponent {
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private nav: NavService,
    private history: HistoryService,
  ) {}

  /**
   * A default path to where user will be redirected if it is the first page of the app seen by the user.
   * It can be a relative path.
   * If not provided, user will be navigated back, which could be outside of the application.
   **/
  default = input<string>(this.nav.routeOf('collaborator'));
  /** True if the default navigation should be relative to the current route */
  isRelative = input(false);
  /** Overidde the expected back path */
  override = input<string>();

  icon = Icon.arrowLeft;

  back() {
    const override = this.override();

    if (override) {
      this.router.navigate([override], { replaceUrl: true });
    } else if (this.history.isFirstPage()) {
      this.router.navigate([this.default()], {
        replaceUrl: true,
        relativeTo: this.isRelative() ? this.route : null,
      });
    } else {
      this.location.back();
    }
  }
}
