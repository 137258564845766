import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  Renderer2,
  signal,
  viewChild,
} from '@angular/core';

export const FOOTER_SELECTOR = 'smw-footer';

@Component({
  selector: FOOTER_SELECTOR,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements AfterViewChecked {
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {
    effect((onCleanup) => {
      if (this.noBack() && this.noPrimary()) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'flex');
      }

      onCleanup(() => {
        this.childrenObserver?.disconnect();
        this.childrenObserver = undefined;
      });
    });
  }

  onRoot = input(false);

  private backRef = viewChild<ElementRef<HTMLElement>>('back');
  private primaryRef = viewChild<ElementRef<HTMLElement>>('primary');

  private noBack = signal(true);
  private noPrimary = signal(true);

  private childrenObserver?: MutationObserver;

  ngAfterViewChecked(): void {
    const backElement = this.backRef()?.nativeElement;
    const primaryElement = this.primaryRef()?.nativeElement;

    this.childrenObserver = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (mutation.target instanceof HTMLElement) {
            const isEmpty = mutation.target.childElementCount === 0;
            if (mutation.target.id === 'primary') {
              this.noPrimary.set(isEmpty);
            }
            if (mutation.target.id === 'back') {
              this.noBack.set(isEmpty);
            }
          }
        }
      }
    });

    if (backElement) {
      this.childrenObserver.observe(backElement, {
        childList: true,
      });
    }

    if (primaryElement) {
      this.childrenObserver.observe(primaryElement, {
        childList: true,
      });
    }
  }
}
