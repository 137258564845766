import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs';

import { isNavigationEnd } from './nav.helper';

@Injectable({ providedIn: 'root' })
export class HistoryService {
  private history: string[] = [];

  constructor(private router: Router) {
    this.router.events.pipe(filter(isNavigationEnd)).subscribe((event) => {
      this.history.push(event.urlAfterRedirects);
    });
  }

  isFirstPage(): boolean {
    return this.history.length <= 1;
  }
}
