@if (!showSuccessModal()) {
  <smw-auth-template>
    <div>
      <smw-icon class="logo" [icon]="logoIcon" />

      <h1 class="title-md mb-3">{{ 'auth_register_title' | translate }}</h1>
      <p class="body-lg mb-8">
        <span class="body-md">{{ 'auth_register_account-exists' | translate }}</span>
        <a
          class="link-md login-link"
          [routerLink]="['auth.login' | route]"
          [queryParamsHandling]="'preserve'"
        >
          {{ 'auth_register_login' | translate }}
        </a>
      </p>

      @if (isConfigLoaded) {
        <form class="form" [formGroup]="form">
          <div class="name">
            <smw-textfield
              class="mb-6"
              formControlName="firstname"
              [placeholder]="'input-firstname-placeholder' | translate"
              [invalid]="hasFormRequestError()"
            />

            <smw-textfield
              class="mb-6"
              formControlName="lastname"
              [placeholder]="'input-lastname-placeholder' | translate"
              [invalid]="hasFormRequestError()"
            />
          </div>

          <smw-textfield
            [type]="'email'"
            class="email mb-6"
            formControlName="email"
            [invalid]="isEmailInvalid()"
            [invalid]="hasFormRequestError()"
            [placeholder]="'input-email-placeholder' | translate"
          />

          @if (isEmailInvalid()) {
            <span class="error-message caption-sm">
              {{ 'auth_login_email_error' | translate }}
            </span>
          }

          <div class="password-group mb-6">
            <smw-password
              formControlName="password"
              [placeholder]="'input-password-placeholder' | translate"
              [invalid]="hasFormRequestError()"
            />
            @if (form.get('password')?.hasError('pattern')) {
              <p class="caption-sm text-secondary-red-500">
                {{ 'error-input-pattern-password' | translate }}
              </p>
            }
          </div>

          @if (isBusinessServiceDisplayed) {
            <smw-select
              class="mb-6"
              formControlName="companyBusinessService"
              [placeholder]="'input-business-service-placeholder' | translate"
              [options]="businessServiceOptions"
              [invalid]="hasFormRequestError()"
              [valueKey]="'id'"
            />
          }

          @if (isJobResponsibilitiesDisplayed) {
            <smw-select
              class="mb-10"
              formControlName="jobResponsabilities"
              [placeholder]="'input-job-responsabilities-placeholder' | translate"
              [invalid]="hasFormRequestError()"
              [options]="jobResponsibilitiesOptions"
            />
          }

          <div class="checkbox rgpd">
            <input type="checkbox" id="rgpd" name="input" formControlName="rgpd" />
            <label
              for="rgpd"
              class="body-sm"
              [innerHTML]="'auth_register_rgpd-agreement' | translate"
            ></label>
          </div>

          <div class="checkbox newsletter">
            <input
              type="checkbox"
              id="newsletterConsent"
              name="input"
              formControlName="newsletterConsent"
            />
            <label
              for="newsletterConsent"
              class="body-sm"
              [innerHTML]="'auth_register_newsletter-agreement' | translate"
            ></label>
          </div>
        </form>
      }
    </div>

    @if (formRequestErrorMessage()) {
      <p class="caption-sm text-secondary-red-500">
        {{ formRequestErrorMessage() | translate }}
      </p>
    }

    <button
      smw-button
      kind="filled"
      class="confirm"
      [disabled]="isConfirmDisabled()"
      (click)="submit()"
    >
      {{ 'auth_register_action' | translate }}
    </button>
  </smw-auth-template>
} @else {
  <div class="successModal">
    <div class="container">
      <h1 class="title-lg">
        {{
          'auth_activation_confirmation_title' | translate: { userFirstName: userFirstName() || '' }
        }}
      </h1>

      <p class="body-lg text-black mb-12">
        {{ 'auth_activation_confirmation_description' | translate }}
      </p>

      <a
        class="!w-fit"
        smw-button
        kind="filled"
        [routerLink]="[callbackURI.pathname]"
        [queryParams]="callbackURI.queryParams"
        (click)="onRedirect()"
      >
        {{ 'auth_activation_confirmation_button' | translate }}
      </a>
    </div>
  </div>

  <div class="right"></div>

  <div class="background-item-1"></div>
  <div class="background-item-2"></div>
  <div class="background-item-3"></div>
}
